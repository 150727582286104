import { useEffect, useRef, useState } from "react";
import { AdvisoryBackground, AdvisoryButton, AdvisoryCover, AdvisoryDivBodyItemsMobile, AdvisoryDivHeaderItemsMobile, AdvisoryDivItemsMobile, AdvisoryDivLineItemsMobile, AdvisoryTextBody, AdvisoryTextItemsMobile, AdvisoryTextTitle, AdvisoryTitle, Author, CornImg, CoverBody, CoverDiv, CoverIcon, CoverIconContainer, FooterBackground, FooterTitle, PotechHistoryFooterAdvisory, PotechHistoryFooterAdvisoryImage, PotechHistoryFooterNewLine, PotechQuoteFooterAdvisory } from "../advisory/AdvisoryElement"
import { CoverManagedBody, DetectHead, FooterManagedBackground, FooterManagedTitle, ManagedButton, ManagedCover, ManagedDivHeaderItemsMobile, ManagedFeatureCol, ManagedFeatureText, ManagedFooterAdvisoryImage, ManagedImage, ManagedImg, ManagedServicesFeaturesDesktop, ManagedText, PackageCellMobile, PackageText, PackageTextHeaderMobile, PackageTextMobile, PackagesCellBodyMobile, PackagesColMobile, ServiceCol, ServiceImg, ServiceRow, ServiceSign, ServiceTextBody, ServiceTextTitle, ServicesAndPackagesContainer, ServicesBackgroundBody, ServicesBackgroundImg, ServicesContainer, ServicesHead, ServicesTitle } from "./ManagedServicesElements";
import ManagedHelpImg from "../../assets/images/managedHelpImage.png";
import { DigitalHeadLogo, FeatureCell, FeatureCellsContainerMobile, FeatureCol, FeatureText, HuntingHead, HuntingText, ManagedPackageText } from "../darkivore/DarkivoreElements";

import No from '../../assets/logos/serviceNo.png';
import Yes from '../../assets/logos/serviceYes.png';

import ServiceLogo from '../../assets/logos/ServiceHeadIcon.png';
import DetectLogo from '../../assets/logos/DetectHeadIcon.png';
import FortifyLogo from '../../assets/logos/FortifyHeadIcon.png';
import RespondLogo from '../../assets/logos/respondHeadIcon.png';

import d1 from '../../assets/logos/d1.png';
import d2 from '../../assets/logos/d2.png';
import d3 from '../../assets/logos/d3.png';
import d4 from '../../assets/logos/d4.png';

import f1 from '../../assets/logos/for1.png';
import f2 from '../../assets/logos/for2.png';
import f3 from '../../assets/logos/for3.png';
import f4 from '../../assets/logos/for4.png';
import f5 from '../../assets/logos/for5.png';

import r1 from '../../assets/logos/r1.png';
import r2 from '../../assets/logos/r2.png';
import r3 from '../../assets/logos/r3.png';

import detectService from '../../assets/logos/managedSearch.png';
import fortifyService from '../../assets/logos/fortifyService.png';
import respondService from '../../assets/logos/respondService.png';

import detectServiceRec from '../../assets/images/DetectRectangle.png';
import detectServiceRec1 from '../../assets/images/DetectRectangle1.png';
import detectServiceRec2 from '../../assets/images/DetectRectangle2.png';
import detectServiceRec3 from '../../assets/images/DetectRectangle3.png';

import fortifyRec from '../../assets/images/FortifyRec.png';
import fortifyRec1 from '../../assets/images/FortifyRec1.png';
import fortifyRec2 from '../../assets/images/FortifyRec2.png';
import fortifyRec3 from '../../assets/images/FortifyRec3.png';
import fortifyRec4 from '../../assets/images/FortifyRec4.png';

import respondRec1 from '../../assets/images/RespondRec1.png';
import RespondRec2 from '../../assets/images/RespondRec2.png';
import RespondRec3 from '../../assets/images/RespondRec3.png';

import ItelicLogo from '../../assets/logos/ItelicLogo.svg';
import FooterDivider from "../../components/FooterDivider";

import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { ChatIcon, CornMobile, ScrollTop } from "../home/HomeElements";
import { useDispatch, useSelector } from "react-redux";
import { resetTargetSection, setTargetSection } from "../../redux/scrollSlice";
import FeatureCardMobile from "../../components/FeatureCardMobile";
import ManagedServicesCardMobile from "../../components/ManagedServicesCardMobile";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

const ManagedServices = ({ isScrolled }) => {

    const detectRef = useRef(null);
    const fortifyRef = useRef(null);
    const respondRef = useRef(null);

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const dispatch = useDispatch();
    const [ref, setRef] = useState(null);

    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])

    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'detect':
                sectionRef = detectRef;
                setRef(detectRef);
                break;
            case 'fortify':
                sectionRef = fortifyRef;
                setRef(fortifyRef);
                break;
            case 'respond':
                sectionRef = respondRef;
                setRef(respondRef);
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            const yOffset = -100;
            const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            dispatch(resetTargetSection());
            // setRef(null);
        }
    }, [targetSection, dispatch]);
    console.log(ref === detectRef);
    const IDR =
        <PackagesColMobile>
            <PackageCellMobile>
                <PackageTextHeaderMobile>
                    IDR
                </PackageTextHeaderMobile>
            </PackageCellMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    EDR
                    <ServiceSign src={Yes} />
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    NIDS
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Office 365 Monitoring
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Published Services Monitoring
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Tactical Threat Intelligence
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    24/7 Monitoring & Alerting
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Response
                    <FeatureCell color={'#006072'} isService={true}>
                        Basic*
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Case Management
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    SIEM
                    <FeatureCell color={'#006072'} isService={true}>
                        On Cloud
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Report Frequency
                    <FeatureCell color={'#006072'} isService={true}>
                        Quarterly
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Compliance Reports (PCI DSS) ISO 27001
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Vulnerability Assessment Report
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    L2/L3 Team
                    <FeatureCell color={'#006072'} isService={true}>
                        Limited
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Customized Use-Case Development
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    CTI
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
        </PackagesColMobile>;

    const EXDR =
        <PackagesColMobile>
            <PackageCellMobile>
                <PackageTextHeaderMobile>
                    ExDR
                </PackageTextHeaderMobile>
            </PackageCellMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    EDR
                    <ServiceSign src={Yes} />
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    NIDS
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Office 365 Monitoring
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Published Services Monitoring
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Tactical Threat Intelligence
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    24/7 Monitoring & Alerting
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Response
                    <FeatureCell color={'#006072'} isService={true}>
                        Basic*
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Case Management
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    SIEM
                    <FeatureCell color={'#006072'} isService={true}>
                        On Cloud
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Report Frequency
                    <FeatureCell color={'#006072'} isService={true}>
                        Quarterly
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Compliance Reports (PCI DSS) ISO 27001
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Vulnerability Assessment Report
                    <FeatureCell color={'#006072'} isService={true}>
                        Quarterly
                    </FeatureCell>

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    L2/L3 Team
                    <FeatureCell color={'#006072'} isService={true}>
                        Limited
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Customized Use-Case Development
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    CTI
                    <ServiceSign src={No} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
        </PackagesColMobile>;

    const SOC =
        <PackagesColMobile>
            <PackageCellMobile>
                <PackageTextHeaderMobile>
                    SOC
                </PackageTextHeaderMobile>
            </PackageCellMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    EDR
                    <ServiceSign src={Yes} />
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    NIDS
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Office 365 Monitoring
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Published Services Monitoring
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Tactical Threat Intelligence
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    24/7 Monitoring & Alerting
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Response
                    <FeatureCell color={'#006072'} isService={true}>
                        Extended*
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Case Management
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    SIEM
                    <FeatureCell color={'#006072'} isService={true}>
                        On Premises
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Report Frequency
                    <FeatureCell color={'#006072'} isService={true}>
                        Monthly
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Compliance Reports (PCI DSS) ISO 27001
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Vulnerability Assessment Report
                    <FeatureCell color={'#006072'} isService={true}>
                        Monthly
                    </FeatureCell>

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    L2/L3 Team
                    <FeatureCell color={'#006072'} isService={true}>
                        Unlimited
                    </FeatureCell>
                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    Customized Use-Case Development
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
            <PackagesCellBodyMobile>
                <PackageTextMobile>
                    CTI
                    <ServiceSign src={Yes} />

                </PackageTextMobile>
            </PackagesCellBodyMobile>
        </PackagesColMobile>;

    const DetectItems = <>
        <AdvisoryTextItemsMobile marginTop={'5vh'}>
            Staying one step ahead of any upcoming malicious activity is always better than finding ways to counter those attacks after they hit us.
            <AdvisoryDivLineItemsMobile />
            Our Managed Detection Services include optimized XDR packages as well as full-fledged SOC activities, suited in a way to meet the exact needs of each organization.
            <AdvisoryDivLineItemsMobile />
            We choose together the services that best answer your company’s needs and you leave it to our teams to cover the required protection on your behalf.
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                IDR – Incident Detection & Response
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={detectServiceRec} />
            <AdvisoryDivBodyItemsMobile>
                Our advanced IDR plan allows us to stay ahead of any cyberattacks directed towards clients’ important digital assets.
                <AdvisoryDivLineItemsMobile />
                It includes managed endpoint and network detection, threat intelligence, quarterly threat hunting reports, and other services that provide swift detection and response against all types of cyber threats.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Ex-DR – Extended Detection & Response
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={detectServiceRec1} />
            <AdvisoryDivBodyItemsMobile>
                Our comprehensive ex-DR package allows clients to take their cybersecurity protection to the next level.
                <AdvisoryDivLineItemsMobile />
                In addition to our robust IDR services, Ex-DR provides vulnerability scanning capabilities and monthly threat hunting updates, covering any emerging threats.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Security Operations Center - SOC
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={detectServiceRec2} />
            <AdvisoryDivBodyItemsMobile>
                Our SOC services provide a fully inclusive protection against cyberattacks with 24/7 monitoring of clients’ network, systems, cloud, APIs/applications, and databases against any suspicious activity.
                <AdvisoryDivLineItemsMobile />
                Our team of security experts is always on standby mode, day and night, responding to any potential threat against your organization, while you focus on your core business operations.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Managed SIEM Services
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={detectServiceRec3} />
            <AdvisoryDivBodyItemsMobile>
                Our Managed Security Information & Event Management (SIEM) services provide a strong and finely crafted solution to prevent alert fatigue and obtain accurate results.
                <AdvisoryDivLineItemsMobile />
                With our advanced SIEM technology, clients can monitor threat activity at a deeper level, quickly identify potential threats, and act before any damage is caused to their business.
                <AdvisoryDivLineItemsMobile />
                We work closely with you to customize our SIEM services so that they perfectly meet your specific needs, ensuring that your business is always ahead of cyberattacks.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const FortifyItems = <>
        <AdvisoryTextItemsMobile marginTop={'5vh'}>
            Fortifying and strengthening clients’ security defenses is a must. It allows for quick and efficient response to any emerging cyberattacks that might address their valuable digital assets and put their business under the risk of interruption.

        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Managed Security Services
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={fortifyRec} />
            <AdvisoryDivBodyItemsMobile>
                This service provides a complete security devices management (e.g., firewalls, WAF and other), safeguarding clients’ infrastructure against evolving threats.
                <AdvisoryDivLineItemsMobile />
                We manage your network, web, mail, and overall security round-the-clock. We keep all your devices updated with the latest patches and monitor traffic for any anomalies.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Managed EDR
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={fortifyRec1} />
            <AdvisoryDivBodyItemsMobile>
                Our Managed Endpoint Detection & Response (EDR) service goes beyond traditional antivirus, EPP, and EDR solutions to provide a comprehensive threat protection for clients’ business operations.
                <AdvisoryDivLineItemsMobile />
                In fact, we use the most advanced technologies and AI-driven algorithms to minimize alert fatigue, reduce false positives and false negatives, and extend your existing capabilities to detect and block all and any threats that matter.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                SOC Maturity Assessment
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={fortifyRec2} />
            <AdvisoryDivBodyItemsMobile>
                Our collective expertise empowers your team through SOC Maturity Assessments to evaluate the effectiveness of your current IT and security operations.
                <AdvisoryDivLineItemsMobile />
                Our team of security Our team conducts thorough review of your people, processes, and technology to identify any gaps and provide actionable recommendations to improve your security posture.
                is always on standby mode, day and night, responding to any potential threat against your organization, while you focus on your core business operations.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Vulnerability Management
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={fortifyRec3} />
            <AdvisoryDivBodyItemsMobile>
                Vulnerability Management is designed to help businesses proactively identify and remediate vulnerabilities before these can be exploited by attackers.
                <AdvisoryDivLineItemsMobile />
                Our team of security experts conducts a comprehensive scan of clients’ network and applications to identify any vulnerabilities.
                <AdvisoryDivLineItemsMobile />
                We eliminate the burden of false positives and prioritize our findings based on risk level. Our detailed remediation guidance goes in depth to ensure that your systems are secured against the latest threats.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Hardening
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={fortifyRec4} />
            <AdvisoryDivBodyItemsMobile>
                Hardening Services ensure that clients’ systems and applications are configured in a secure manner, adopting standards ranging from CIS benchmarks to industry best practices.
                <AdvisoryDivLineItemsMobile />
                Our team understands the challenges of applying feasible thorough controls, which is why we develop creative measures and workarounds to minimize the attack surface and reduce the risk of successful attacks.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const RespondItems = <>
        <AdvisoryTextItemsMobile marginTop={'5vh'}>
            When security incidents occur, it is always a matter of being solidly prepared for confronting those attacks in the most efficient and alert manner.
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Incident Response
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={respondRec1} />
            <AdvisoryDivBodyItemsMobile>
                Our Incident Response service provides a comprehensive, end-to-end approach to managing security incidents.
                <AdvisoryDivLineItemsMobile />
                Our team of experienced security professionals works with you to build an incident response plan tailored to your organization's specific needs and requirements.
                <AdvisoryDivLineItemsMobile />
                And in the event of a security incident, we quickly assess the situation, contain the threat, and restore systems to their normal state as quickly as possible for business processes and operations continuity.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Managed SOAR
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={RespondRec2} />
            <AdvisoryDivBodyItemsMobile>
                Our Managed Security Orchestration, Automation and Response SOAR service provides a streamlined and automated incident response process, which helps clients respond to security incidents more efficiently.
                <AdvisoryDivLineItemsMobile />
                By integrating with your existing security tools and processes, our solution enables you to detect threats and respond to them in real time, significantly reducing the time and effort required to manage incidents.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <ManagedDivHeaderItemsMobile>
                Case Management
            </ManagedDivHeaderItemsMobile>
            <ManagedImage src={RespondRec3} />
            <AdvisoryDivBodyItemsMobile>
                Managing security incidents can be a complex and challenging process. Our Case Management services alleviate this burden by allowing clients to keep track of their incident response activities and ensure that everything is being done to mitigate the impact of a breach.
                <AdvisoryDivLineItemsMobile />
                With our centralized platform, you can easily manage incidents, assign tasks, and collaborate with your team to resolve issues rapidly and powerfully.
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
    const discoverJump = useRef(null);

    return <>
        <Helmet>
            <title>Managed Services: Detect, Fortify & Respond </title>
            <meta name="description" content="State-of-the-art SOC providing Managed & Fortification Security Services." />
        </Helmet>
        <AdvisoryBackground>
            <ManagedImg />
            <AdvisoryTitle>
                <AdvisoryTextTitle>MANAGED SERVICES</AdvisoryTextTitle>
                <AdvisoryTextBody>
                    Through our state-of-the-art SOC - Security Operations Center - we provide Managed & Fortification Security Services that detect and respond to cybersecurity incidents, and strengthen clients’ security defenses by keeping them ahead of the latest threats.
                </AdvisoryTextBody>
                <ManagedButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</ManagedButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </AdvisoryTitle>
        </AdvisoryBackground>
        <ManagedCover>
            <CornImg>
                <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                            <stop offset="0.253125" stopColor="#bdbdbd" />
                            <stop offset="0.711458" stopColor="#bababa" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornImg>
            <CornMobile>
                <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.3402 37.0986C44.909 37.8203 44.2867 38.4194 43.5365 38.8353C42.7863 39.2511 41.9349 39.4688 41.0689 39.4663H4.92635C4.06024 39.4694 3.2087 39.2522 2.4582 38.8369C1.70769 38.4215 1.08496 37.8227 0.653222 37.1013C0.22149 36.3799 -0.00386255 35.5616 5.00943e-05 34.7294C0.00396274 33.8973 0.237 33.0809 0.675499 32.3633L18.7262 2.37293C19.1584 1.65164 19.7807 1.05252 20.5306 0.635934C21.2805 0.219344 22.1314 0 22.9976 0C23.8638 0 24.7147 0.219344 25.4646 0.635934C26.2145 1.05252 26.8369 1.65164 27.269 2.37293L45.3197 32.3633C45.7615 33.0794 45.9963 33.8958 46 34.7283C46.0036 35.5607 45.7758 36.379 45.3402 37.0986Z" fill="url(#paint0_linear_1_662)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_1_662" x1="0" y1="19.7332" x2="46" y2="19.7332" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#bdbdbd" />
                            <stop offset="1" stop-color="#b5b6ba" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornMobile>
            <CoverManagedBody>
                Whether you need help with threat detection and response, vulnerability management, hardening, or any other security-related service, we have you covered. Our SOC experts continuously monitor internal and external risks addressed to your digital assets, always ready for swift response to breaches and menaces. Our main goal is to ensure smooth business continuity and safe IT environments, by eradicating or minimizing all and any cybersecurity risks.
            </CoverManagedBody>
            <CoverIconContainer width={'35vw'}>
                <CoverDiv onClick={() => { dispatch(setTargetSection('detect')) }}>
                    <CoverIcon src={detectService} />
                    <div>Detect</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('fortify')) }}>
                    <CoverIcon src={fortifyService} />
                    <div>Fortify</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('respond')) }}>
                    <CoverIcon src={respondService} />
                    <div>Respond</div>
                </CoverDiv>
            </CoverIconContainer>
        </ManagedCover>
        {/* body */}
        <ServicesBackgroundBody ref={discoverJump}>
            <ServicesBackgroundImg />
            <ServicesTitle>
                {/* services and packages */}
                <ServicesAndPackagesContainer>
                    <ServicesHead marginTop={'5vh'} marginBottom={'5vh'} color={'white'}>
                        SERVICES & PACKAGES
                    </ServicesHead>
                    <ManagedPackageText>
                        We recognize that every organization has unique security needs, and that's why we offer tailored Managed Services to meet those needs.
                    </ManagedPackageText>
                    <ManagedServicesFeaturesDesktop>
                        <ManagedFeatureCol width={'22.383vw'}>
                            <FeatureCell isService={true} borderRadius={'15px 15px 0px 0px'} color={'#1F3B71'}>
                                <ManagedFeatureText isService={true}>
                                    SERVICE
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    EDR
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    NIDS
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Office 365 Monitoring
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Published Services Monitoring
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Tactical Threat Intelligence
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    24/7 Monitoring & Alerting
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Response
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Case Management
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    SIEM
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Report Frequency
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Compliance Reports (PCI DSS) ISO 27001
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Vulnerability Assessment Report
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    L2/L3 Team
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    Customized Use-Case Development
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true} borderRadius={'0px 0px 15px 15px'} justifyContent={'start'} paddingLeft={'8%'}>
                                <ManagedFeatureText isService={true}>
                                    CTI
                                </ManagedFeatureText>
                            </FeatureCell>
                        </ManagedFeatureCol>
                        <ManagedFeatureCol>
                            <FeatureCell isService={true} borderRadius={'15px 15px 0px 0px'} color={'#1F3B71'}>
                                <ManagedFeatureText isService={true}>
                                    IDR
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#006072'} isService={true}>
                                Basic*
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                On Cloud
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Quarterly
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Limited
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell borderRadius={'0px 0px 15px 15px'} isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                        </ManagedFeatureCol>
                        <ManagedFeatureCol>
                            <FeatureCell isService={true} borderRadius={'15px 15px 0px 0px'} color={'#1F3B71'}>
                                <ManagedFeatureText isService={true}>
                                    ExDR
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#006072'} isService={true}>
                                Basic*
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                On Cloud
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Quarterly
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Quarterly
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Limited
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                            <FeatureCell isService={true} borderRadius={'0px 0px 15px 15px'}>
                                <ServiceSign src={No} />
                            </FeatureCell>
                        </ManagedFeatureCol>
                        <ManagedFeatureCol>
                            <FeatureCell isService={true} borderRadius={'15px 15px 0px 0px'} color={'#1F3B71'}>
                                <ManagedFeatureText isService={true}>
                                    SOC
                                </ManagedFeatureText>
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#006072'} isService={true}>
                                Extended
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                On Premises
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Monthly
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Monthly
                            </FeatureCell>
                            <FeatureCell color={'#00ADBB'} isService={true}>
                                Unlimited
                            </FeatureCell>
                            <FeatureCell isService={true}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                            <FeatureCell isService={true} borderRadius={'0px 0px 15px 15px'}>
                                <ServiceSign src={Yes} />
                            </FeatureCell>
                        </ManagedFeatureCol>
                    </ManagedServicesFeaturesDesktop>
                    <FeatureCellsContainerMobile>
                        <FeatureCardMobile width={'80%'} title="IDR" items={IDR} />
                        <FeatureCardMobile width={'80%'} title="ExDR" items={EXDR} />
                        <FeatureCardMobile width={'80%'} title="SOC" items={SOC} />
                    </FeatureCellsContainerMobile>
                </ServicesAndPackagesContainer>
                {/* detect */}
                <ServicesContainer>
                    <DigitalHeadLogo ref={isMobile ? undefined : detectRef} src={DetectLogo} />
                    <DetectHead>
                        DETECT
                    </DetectHead>
                    <ManagedText>
                        Staying one step ahead of any upcoming malicious activity is always better than finding ways to counter those attacks after they hit us.
                        Our Managed Detection Services include optimized XDR packages as well as full-fledged SOC activities, suited in a way to meet the exact needs of each organization.
                        <AdvisoryDivLineItemsMobile />
                        We choose together the services that best answer your company’s needs and you leave it to our teams to cover the required protection on your behalf.
                    </ManagedText>
                    <ServiceCol>
                        <ServiceRow>
                            <ServiceImg src={d1} />
                            <div>
                                <ServiceTextTitle>
                                    IDR – Incident Detection & Response
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our advanced IDR plan allows us to stay ahead of any cyberattacks directed towards clients’ important digital assets.
                                    <AdvisoryDivLineItemsMobile />

                                    It includes managed endpoint and network detection, threat intelligence, quarterly threat hunting reports, and other services that provide swift detection and response against all types of cyber threats.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                        <ServiceRow>
                            <div>
                                <ServiceTextTitle>
                                    Ex-DR – Extended Detection & Response
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our comprehensive ex-DR package allows clients to take their cybersecurity protection to the next level.
                                    <br />
                                    <br />
                                    In addition to our robust IDR services, Ex-DR provides vulnerability scanning capabilities and monthly threat hunting updates, covering any emerging threats.
                                </ServiceTextBody>
                            </div>
                            <ServiceImg src={d2} />
                        </ServiceRow>
                        <ServiceRow>
                            <ServiceImg src={d3} />
                            <div>
                                <ServiceTextTitle>
                                    Security Operations Center - SOC
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our SOC services provide a fully inclusive protection against cyberattacks with 24/7 monitoring of clients’ network, systems, cloud, APIs/applications, and databases against any suspicious activity.
                                    <br />
                                    <br />
                                    Our team of security experts is always on standby mode, day and night, responding to any potential threat against your organization, while you focus on your core business operations.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                        <ServiceRow>
                            <div>
                                <ServiceTextTitle>
                                    Managed SIEM Services
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our Managed Security Information & Event Management (SIEM) services provide a strong and finely crafted solution to prevent alert fatigue and obtain accurate results.
                                    <br />
                                    <br />
                                    With our advanced SIEM technology, clients can monitor threat activity at a deeper level, quickly identify potential threats, and act before any damage is caused to their business.
                                    <br />
                                    <br />
                                    We work closely with you to customize our SIEM services so that they perfectly meet your specific needs, ensuring that your business is always ahead of cyberattacks.
                                </ServiceTextBody>
                            </div>
                            <ServiceImg src={d4} />
                        </ServiceRow>
                    </ServiceCol>
                </ServicesContainer>
                {/* fortify */}
                <ServicesContainer height={'215vh'}>
                    <DigitalHeadLogo ref={isMobile ? undefined : fortifyRef} src={FortifyLogo} />
                    <DetectHead>
                        FORTIFY
                    </DetectHead>
                    <ManagedText>
                        Fortifying and strengthening clients’ security defenses is a must. It allows for quick and efficient response to any emerging cyberattacks that might address their valuable digital assets and put their business under the risk of interruption.
                    </ManagedText>
                    <ServiceCol height={'175vh'}>
                        <ServiceRow>
                            <ServiceImg src={f1} />
                            <div>
                                <ServiceTextTitle>
                                    Managed Security Services
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    This service provides a complete security devices management (e.g., firewalls, WAF and other), safeguarding clients’ infrastructure against evolving threats.
                                    <AdvisoryDivLineItemsMobile />
                                    We manage your network, web, mail, and overall security round-the-clock. We keep all your devices updated with the latest patches and monitor traffic for any anomalies.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                        <ServiceRow>
                            <div>
                                <ServiceTextTitle>
                                    Managed EDR
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our Managed Endpoint Detection & Response (EDR) service goes beyond traditional antivirus, EPP, and EDR solutions to provide a comprehensive threat protection for clients’ business operations.

                                    <AdvisoryDivLineItemsMobile />

                                    In fact, we use the most advanced technologies and AI-driven algorithms to minimize alert fatigue, reduce false positives and false negatives, and extend your existing capabilities to detect and block all and any threats that matter.
                                </ServiceTextBody>
                            </div>
                            <ServiceImg src={f2} />
                        </ServiceRow>
                        <ServiceRow>
                            <ServiceImg src={f3} />
                            <div>
                                <ServiceTextTitle>
                                    SOC Maturity Assessment
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our collective expertise empowers your team through SOC Maturity Assessments to evaluate the effectiveness of your current IT and security operations.

                                    <AdvisoryDivLineItemsMobile />

                                    Our team conducts thorough review of your people, processes, and technology to identify any gaps and provide actionable recommendations to improve your security posture.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                        <ServiceRow>
                            <div>
                                <ServiceTextTitle>
                                    Vulnerability Management
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Vulnerability Management is designed to help businesses proactively identify and remediate vulnerabilities before these can be exploited by attackers.

                                    <AdvisoryDivLineItemsMobile />

                                    Our team of security experts conducts a comprehensive scan of clients’ network and applications to identify any vulnerabilities.
                                    <AdvisoryDivLineItemsMobile />

                                    We eliminate the burden of false positives and prioritize our findings based on risk level. Our detailed remediation guidance goes in depth to ensure that your systems are secured against the latest threats.
                                </ServiceTextBody>
                            </div>
                            <ServiceImg src={f4} />
                        </ServiceRow>
                        <ServiceRow>
                            <ServiceImg src={f5} />
                            <div>
                                <ServiceTextTitle>
                                    Hardening
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Hardening Services ensure that clients’ systems and applications are configured in a secure manner, adopting standards ranging from CIS benchmarks to industry best practices.

                                    <AdvisoryDivLineItemsMobile />

                                    Our team understands the challenges of applying feasible thorough controls, which is why we develop creative measures and workarounds to minimize the attack surface and reduce the risk of successful attacks.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                    </ServiceCol>
                </ServicesContainer>
                {/* respond */}
                <ServicesContainer height={'140vh'}>
                    <DigitalHeadLogo ref={isMobile ? undefined : respondRef} src={RespondLogo} />
                    <DetectHead>
                        RESPOND
                    </DetectHead>
                    <ManagedText>
                        When security incidents occur, it is always a matter of being solidly prepared for confronting those attacks in the most efficient and alert manner.
                    </ManagedText>
                    <ServiceCol height={'100vh'}>
                        <ServiceRow>
                            <ServiceImg src={r1} />
                            <div>
                                <ServiceTextTitle>
                                    Incident Response
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our Incident Response service provides a comprehensive, end-to-end approach to managing security incidents.
                                    <AdvisoryDivLineItemsMobile />

                                    Our team of experienced security professionals works with you to build an incident response plan tailored to your organization's specific needs and requirements.
                                    <AdvisoryDivLineItemsMobile />

                                    And in the event of a security incident, we quickly assess the situation, contain the threat, and restore systems to their normal state as quickly as possible for business processes and operations continuity.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                        <ServiceRow>
                            <div>
                                <ServiceTextTitle>
                                    Managed SOAR
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Our Managed Security Orchestration, Automation and Response SOAR service provides a streamlined and automated incident response process, which helps clients respond to security incidents more efficiently.
                                    <AdvisoryDivLineItemsMobile />
                                    By integrating with your existing security tools and processes, our solution enables you to detect threats and respond to them in real time, significantly reducing the time and effort required to manage incidents.
                                </ServiceTextBody>
                            </div>
                            <ServiceImg src={r2} />
                        </ServiceRow>
                        <ServiceRow>
                            <ServiceImg src={r3} />
                            <div>
                                <ServiceTextTitle>
                                    Case Management
                                </ServiceTextTitle>
                                <ServiceTextBody>
                                    Managing security incidents can be a complex and challenging process. Our Case Management services alleviate this burden by allowing clients to keep track of their incident response activities and ensure that everything is being done to mitigate the impact of a breach.
                                    <AdvisoryDivLineItemsMobile />
                                    With our centralized platform, you can easily manage incidents, assign tasks, and collaborate with your team to resolve issues rapidly and powerfully.
                                </ServiceTextBody>
                            </div>
                        </ServiceRow>
                    </ServiceCol>
                </ServicesContainer>

                <ManagedServicesCardMobile setRef={setRef} isOpen={ref === detectRef} refA={isMobile ? detectRef : undefined} logo={DetectLogo} title={"DETECT"} items={DetectItems} />
                <ManagedServicesCardMobile setRef={setRef} isOpen={ref === fortifyRef} refA={isMobile ? fortifyRef : undefined} logo={FortifyLogo} title={"FORTIFY"} items={FortifyItems} />
                <ManagedServicesCardMobile setRef={setRef} isOpen={ref === respondRef} refA={isMobile ? respondRef : undefined} logo={RespondLogo} title={"RESPOND"} items={RespondItems} />
            </ServicesTitle>
        </ServicesBackgroundBody>
        <FooterManagedBackground>
            <FooterManagedTitle>
                <PotechQuoteFooterAdvisory>
                    “It is no longer a matter of preventing data breaches. It is about hastening detection.”
                    <Author>
                        <div>Jad Tabet</div>
                        <div>Managing Partner, ITelic</div>
                    </Author>
                </PotechQuoteFooterAdvisory>
                <PotechHistoryFooterAdvisory>
                    <ManagedFooterAdvisoryImage src={ItelicLogo} />
                    <PotechHistoryFooterNewLine>
                        ITelic is a full-fledged Security Operations Center SOC and Managed Services provider, founded in 2021 with the purpose of reinforcing organizations’ resilience towards cyberthreats. Our clients entrust us for integrating their security data collection with analysis & reporting, providing them with continuous event monitoring and correlation with threat intelligence.
                    </PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>ITelic’s state-of-the-art SOC provides 24/7 Managed Security Services for the detection of cybersecurity incidents, and the response to internal and external threats.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>Our services currently cover more than 50 clients around the world to whom we offer the peace of mind, knowing that they have a team of security experts committed to constantly improving their security posture and protecting their critical assets with a sound IT infrastructure.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>
                        ITelic guiding principles rest on three building blocks: People, Process & Technology:
                        Our people are experts, constantly receiving the most up-to-date trainings, developing experience and transferring knowledge.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>Our Processes ensure a solid SOC governance and our latest Technologies reflect the ever-evolving needs of secure IT environments.</PotechHistoryFooterNewLine>
                </PotechHistoryFooterAdvisory>
            </FooterManagedTitle>
        </FooterManagedBackground>
        <FooterDivider />
    </>
}

export default ManagedServices;