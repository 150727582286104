import styled from "styled-components";
import img from '../../assets/images/adImg.png';
import imgMob from '../../assets/images/advisoryBackMobile.png';
import imgAdMob from '../../assets/images/advisoryBodyMobile.png';
import imgV from '../../assets/images/vigilanceImage.png';
import imgD from '../../assets/images/DefenseImageBackground.png';
import imgS from '../../assets/images/StrategyImageBackground.png';
import imgF from '../../assets/images/footerAdvisoryBackgorund.png';
import imgFooterMob from '../../assets/images/FooterBackAdvisoryMobile.png';

export const AdvisoryBackground = styled.nav`
  width: 100%;
  height: 65vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: 100vh;
  }
`;

export const AdvisoryImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${img});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100%;
    background-image: url(${imgMob});
  }
`;

export const AdvisoryTitle = styled.div`
  color: white;
  height: 65vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
	font-family: "HelveticaLt", Arial, sans-serif;
  /* font-family: 'Helvetica Light'; */
  @media screen and (max-width: 800px) {
    height: 110vh;
  }
`;

export const AdvisoryTextTitle = styled.h1`
  margin-top: 14vh;
  font-size: 1.8vw;
  font-weight: 700;
  margin-bottom: 3vh;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    text-align: center;
    padding-inline: 15%;
    margin-top: 1vh;
  }
`;

export const AdvisoryTextBody = styled.div`
    width: 70vw;
    font-weight: 100;
    font-size: 1.2vw;
    text-align: center;
    margin-bottom: 4vh;
    font-family: "HelveticaLt", Arial, sans-serif;
    line-height: 150%;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding-inline: 1%;
      box-sizing: border-box;
      margin-top: 2vh;
    }
`;

export const AdvisoryButton = styled.div`
border-radius: 5px;
background: var(--consulting-complementary-gradient, linear-gradient(90deg, #F1D278 25.31%, #D1AA33 71.15%));
background-blend-mode: multiply;
/* Drop Shadow Style */
cursor: pointer;
box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
color: var(--black, #000);
text-align: center;
font-weight: 400;
width: 7vw;
padding: 0.4vw;
font-size: 1vw;
font-family: 'Helvetica';
@media screen and (max-width: 800px) {
  width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
  }
`

export const AdvisoryCover = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
text-align: center;
height: 50vh;
/* font-family: 'Helvetica Light'; */
font-family: "HelveticaLt", Arial, sans-serif;
background: var(--ocean-gradient, linear-gradient(90deg, rgba(214, 214, 214, 1) 0%, rgba(161, 161, 161, 1) 100%));
background-repeat: no-repeat;
background-attachment: fixed;
background-blend-mode: multiply;
position: relative;
@media screen and (max-width: 800px) {
  height: auto;
}
`

export const CoverTitle = styled.h2`
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 0vh;
    margin-bottom: 0vh;
    text-transform: uppercase;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding-inline: 10vw;
      margin-top: 10vh;
      margin-bottom: 9vh;
    }
`
export const CoverBody = styled.div`
    font-size: 1vw;
    width: 70vw;
    margin-top: -1vh;
    margin-bottom: 0vh;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      margin-bottom: 9vh;
    }
`

export const CoverIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    text-align: center;
    width: ${props => props.width ?? '45vw'};
    z-index: 1;
    font-size: 0.9vw;
    font-family: Arial, Helvetica, sans-serif;
    @media screen and (max-width: 800px) {
      display: none;
    }
`;

export const CoverDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CoverIcon = styled.img`
    width: 6.5vw;
    margin-bottom: 10px;
    filter: drop-shadow(20px 10px 20px rgba(0, 0, 0, 0.3));
    cursor: pointer;
`

export const CornImg = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${props => props.zIndex ?? 2};
  @media screen and (max-width: 800px) {
    top: -4vh;
  }
`;

export const VigilanceBackground = styled.nav`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const VigilanceImg = styled.div`
  width: 100%;
  height: ${props => props.height ?? '190%'};
  position: absolute;
  background-image: url(${imgV});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const VigilanceEyeIcon = styled.img`
  position: absolute;
  width: 200px;
  top: 12vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 160px;
    top: 12vh;
  }
`;

export const VigilancePrimeEyeIcon = styled.img`
  position: absolute;
  width: 200px;
  top: 11vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 160px;
    top: 11vh;
  }
`;

export const VigilanceBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.height ?? '240vh'};
  z-index: 1;
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 23vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10vh;
`;

export const VigilanceBodyHead = styled.div`
  margin-top: 8vh;
  z-index: 1;
`;

export const VigilanceBodyHeadText = styled.h2`
  color: var(--consulting-light-red, #E20D1A);
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8vw;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: ${props => props.marginBottom ?? '5vh'};
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
`;

export const VigilanceBodyText = styled.div`
  color: var(--black, #000);
  text-align: center;
  width: 59vw;
  font-size: 0.9vw;
`;

export const VigilanceCardContainer = styled.div`
  display: flex;
  width: 59vw;
  justify-content: space-between;
  height: 30vh;
`;

export const VigilanceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  text-align: center;
  width: 55vw;
`;

export const VigilanceImage = styled.img`
  width: ${props => props.width ?? '5vw'};
  margin-bottom: 5vh;
  height: 375px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  z-index: 1;
`;

export const VigilanceImageDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-start;
`;

export const VigilanceImageDescTitle = styled.div`
  color: var(--consulting-light-red, #E20D1A);
  /* Internal BodyCopy Subtitles */
  font-size: 1.3vw;
  text-transform: uppercase;
  text-align: start;
  margin-bottom: 3vh;
`;

export const VigilanceImageDescBody = styled.div`
  color: var(--black, #000);
  font-size: 1vw;
  text-align: start;
  width: 25vw;
  font-size: 0.9vw;
`;

export const DefenseBackground = styled.nav`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const DefenseRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  text-align: center;
  width: 58vw;
`;

export const DefenseCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const DefenseBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 150vh;
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 20vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const DefenseImg = styled.div`
  width: 100%;
  height: 107%;
  position: absolute;
  background-image: url(${imgD});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  margin-top: -7vh;
`;

export const StrategyBackground = styled.nav`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const StrategyImg = styled.div`
  width: 100%;
  height: 101%;
  position: absolute;
  margin-top: -1vh;
  background-image: url(${imgS});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const FooterBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FooterImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${imgF});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    background-image: url(${imgFooterMob});
    height: 100%;
  }
`;

export const FooterTitle = styled.div`
  color: white;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: start;
  align-content: start;
  width: 90%;
  margin: auto;
  text-align: start;
  margin-top: 12vh;
  margin-bottom: 12vh;
  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: 0vh;
    width: 100%;
  }
`;

export const PotechQuoteFooterAdvisory = styled.div`
  color: var(--white, #FFF);
  text-align: right;
  font-size: 2.2vw;
  display: flex;
  width: 40vw;
  font-weight: 100;
  flex-direction: column;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 75%;
    text-align: start;
    margin-top: 5vh;
  }
`;

export const PotechHistoryFooterAdvisory = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width: 40vw;
  font-size: 1vw;
  @media screen and (max-width: 800px) {
    margin-top: 5vh;
    width: 75%;
  }
`;

export const AdvisoryBodyMobile = styled.nav`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  padding-bottom: 10vh;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const AdvisoryBodyMobileImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${imgAdMob});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-position: start;
`;

export const ImageAdvisoryItemsMobile = styled.img`
  width: 80%;
  object-fit: cover;
  box-sizing: border-box;
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 166px;
  border-radius: 20px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  z-index: 1;
`;

export const AdvisoryTextItemsMobile = styled.div`
  color: var(--Black, #000);
  text-align: center;
  /* Body 01 */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.889%;
  width: 80%;
  margin-top: ${props => props.marginTop ?? ''};
`;

export const AdvisoryDivItemsMobile = styled.div`

  border-radius: 15px;
  background: var(--White, #FFF);

  /* Drop Shadow Style */
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  width: 80%;
  margin-top: 5vh;
`;

export const AdvisoryDivHeaderItemsMobile = styled.div`

  color: ${props => props.color ?? 'var(--Consulting-Light-Red, #E20D1A)'};
  /* Resp-Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px #CECECE solid;
  text-align: start;
  padding: 6%;
  padding-inline: 9%;
`;

export const AdvisoryDivBodyItemsMobile = styled.div`

  color: var(--Black, #000);
  /* Resp-Body */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  padding: 9%;
  text-align: start;
`;

export const AdvisoryDivLineItemsMobile = styled.div`
  margin-top: ${props => props.marginTop ?? '2vh'};
`;

export const PotechHistoryFooterAdvisoryImage = styled.img`
  margin-bottom: 2vh;
  width: 18vw;

  @media screen and (max-width: 800px) {
    width: 80%;
    align-self: start;
  }
`;

export const PotechHistoryFooterNewLine = styled.div`

  margin-top: 3vh;
  font-family: "HelveticaLt", Arial, sans-serif;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    margin-top: 2vh;
  }
`;

export const Author = styled.div`
  font-size: 1.3vw;
  margin-top: 5vh;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-top: 3vh;
    margin-bottom: 10vh;
  }
`;