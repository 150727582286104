import styled from "styled-components";
import img from '../../assets/images/managedServicesBackground.png';
import imgServices from '../../assets/images/servicesBackground.png';

const height = '850vh';

export const ManagedImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${img});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const ManagedCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 50vh;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  background: var(--Generic-Neutral-Grey-Gradient, linear-gradient(90deg, #D6D6D6 25.31%, #A1A1A1 71.15%));
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: multiply;
  position: relative;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const ManagedButton = styled.div`
  border-radius: 5px;
  background: linear-gradient(90deg, #D6D6D6 25.31%, #A1A1A1 71.15%);
  background-blend-mode: multiply;
  /* Drop Shadow Style */
  cursor: pointer;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  color: var(--black, #000);
  text-align: center;
  font-weight: 400;
  width: 7vw;
  padding: 0.4vw;
  font-size: 1vw;
  font-family: 'Helvetica';
  @media screen and (max-width: 800px) {
    width: auto;
      font-size: 14px;
      padding: 2%;
      padding-inline: 5%;
      margin-top: 2vh;
  }
`;

export const ManagedFeatureCol = styled.div`
  border-radius: 15px;
  background: var(--lightest-grey, #F0F0F0);
  /* Testimonials Box Shadow */
  box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.17);
  /* height: 1370px; */
  /* width: 240px; */
  width: ${props => props.width ?? '11.002604166666666vw'};
  height: 170vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ServicesBackgroundBody = styled.nav`
  width: 100%;
  height: ${height};
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const ServicesBackgroundImg = styled.div`
  width: 100vw;
  height: ${height};
  position: absolute;
  background-image: url(${imgServices});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100%;
  }
`;

export const ServicesTitle = styled.div`
  color: white;
  height: ${height};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around ;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
    padding-bottom: 10vh;
  }
`;

export const ServicesContainer = styled.div`
  color: white;
  height: ${props => props.height ?? '190vh'};
  display: flex;
  border-radius: 15px;
  width: 76vw;
  background-color: white;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const DetectHead = styled.div`
color: var(--Itelic-Dark-Moonstone, #006072);
  text-align: center;
  /* Heading H1 */
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  margin-bottom: 5vh;
  margin-top: 0vh;
  color: #006072;
  font-size: 1.8vw;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 13vh;
    margin-bottom: 7vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const ManagedText = styled.div`
  width: 61vw;
  font-size: 0.95vw;
  color: black;
  text-align: center;
  margin-bottom: 5vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    text-align: center;
    margin-bottom: 5vh;
  }
`;

export const ServicesHead = styled.h2`
  color: ${props => props.color ?? 'var(--darkivore-dark-blue, #1F3B71)'};
  text-align: center;
  font-weight: normal;
  /* Heading H1 */
  font-size: 1.8vw;
  text-transform: uppercase;
  margin-top: ${props => props.marginTop ?? '15vh'};
  margin-bottom: ${props => props.marginBottom ?? ''};
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 0;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const ServicesAndPackagesContainer = styled.div`
  color: white;
  height: 220vh;
  display: flex;
  border-radius: 15px;
  width: 76vw;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  background-color: 'transparent';
  margin-top: -20vh;
  margin-bottom: -10vh;
  @media screen and (max-width: 800px) {
    height: auto;
    margin-top: 10vh;
    margin-bottom: 15vh;
  }
`;

export const PackagesColMobile = styled.div`
  border-radius: 15px;
  background: var(--lightest-grey, #F0F0F0);
  /* Testimonials Box Shadow */
  box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.17);
  /* height: 1370px; */
  height: auto;
  width: 100%;
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PackageCellMobile = styled.div`
  height: 99.18px;
  color: #1F3B71;
  background: var(--white, #FFF);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 15px 15px 0px 0px;
  color: #1F3B71;
  /* margin-bottom: 5vh; */
`;

export const PackageTextHeaderMobile = styled.div`
  color: var(--Itelic-Dark-Moonstone, #006072);
  text-align: center;
  /* Resp-Sub-title */
  font-family: "HelveticaLt", Arial, sans-serif;
  padding-inline: 10%;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const PackagesCellBodyMobile = styled.div`
  height: 117.894px;
  color: #1F3B71;
  background: var(--white, #FFF);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-inline: 5%;
  box-sizing: border-box;
  align-items: center;
  font-size: 16px;
  box-shadow: 9.94898px 9.94898px 19.89796px 3.31633px rgba(0, 0, 0, 0.15);
  z-index: 1;
  margin-top: 23px;
`;

export const PackageTextMobile = styled.div`
  font-size: 16px;
  color: var(--Itelic-Light-Moonstone, #006072);
  padding: 10%;
  padding-inline: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ServiceSign = styled.img`
  width: 2.5vw;
  @media screen and (max-width: 800px) {
    width: 30px;
    margin-top: 15px;
  }
`;


export const ServiceCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  /* width: 60vw; */
  height: ${props => props.height ?? '140vh'};
  /* height: 110%; */
`;

export const ManagedFeatureText = styled.div`
  font-size: 1vw;
  color: ${(props) => props.isService ? '#006072' : '#689922'};
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: end;
  font-family: Arial, Helvetica, sans-serif;
`;

export const ServiceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  width: 61vw;
`;

export const ServiceImg = styled.img`
  width: 13vw;
  filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
`;

export const ServiceTextTitle = styled.h3`
  color: var(--itelic-dark-moonstone, #006072);
  margin-bottom: 3vh;
  margin-top: 0;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
`;

export const ServiceTextBody = styled.div`
  color: var(--black, #000);
  font-size: 0.95vw;
  width: 46vw;
`;

export const CoverManagedBody = styled.div`
    font-size: 0.97vw;
    width: 69vw;
    font-family: "HelveticaLt", Arial, sans-serif;
    line-height: 150%;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      margin-bottom: 9vh;
      padding-top: 9vh;
    }
`

export const ManagedServicesFeaturesDesktop = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7vh;
  justify-content: space-between;
  width: 61vw;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ManagedDivHeaderItemsMobile = styled.div`

  color: var(--Itelic-Dark-Moonstone, #006072);
  text-align: center;
  /* Resp-Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 6%;
  padding-inline: 10%;
`;

export const ManagedImage = styled.img`
  width: 100%;
`;

export const FooterManagedBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  background: linear-gradient(90deg, #00ADBB 0%, #006072 100%);
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FooterManagedTitle = styled.div`
  color: white;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  align-items: start;
  width: 90%;
  margin: auto;
  text-align: center;

  padding-top: 13vh;
  padding-bottom: 13vh;

  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center ;
    padding-top: 5vh;
    padding-bottom: 0vh;
    width: 100%;
  }
`;

export const ManagedFooterAdvisoryImage = styled.img`
  margin-bottom: 2vh;
  width: 13vw;

  @media screen and (max-width: 800px) {
    width: 80%;
    align-self: start;
  }
`;