import { AdvisoryBodyMobile, AdvisoryBodyMobileImg, AdvisoryButton, AdvisoryDivBodyItemsMobile, AdvisoryDivHeaderItemsMobile, AdvisoryDivItemsMobile, AdvisoryDivLineItemsMobile, AdvisoryTextBody, AdvisoryTextItemsMobile, AdvisoryTextTitle, AdvisoryTitle, CornImg, CoverDiv, CoverIcon, CoverIconContainer, FooterBackground, FooterTitle, ImageAdvisoryItemsMobile, PotechHistoryFooterAdvisory, VigilanceBody, VigilanceEyeIcon, VigilanceImage } from "../advisory/AdvisoryElement";
import { ChatIcon, ScrollTop } from "../home/HomeElements";
import { AcademyBackground, AcademyBody, AcademyBodyText, AcademyButton, AcademyCardContainer, AcademyCover, AcademyEyeIcon, AcademyImg, AcademyTextBody, AcademyTextTitle, AcademyTitle, CertBody, CertText, CertTitle, FooterAcademyBackground, FooterAcademyTitle, TrainingBackground, TrainingBodyMobileImg, TrainingImg, TrainingTitle } from "./AcademyElements";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import A1 from '../../assets/logos/AcademyIcon1.png'
import A2 from '../../assets/logos/AcademyIcon2.png'
import WTraining from '../../assets/logos/whiteTraining.png'
import WCert from '../../assets/logos/whiteCert.png'
import academyImg from '../../assets/images/AcademyImage.png'
import VigilanceCard from "../../components/VigilanceCard";
import { ModuleAcademyBorderWrap, ModuleAcademyBorderWrapBody, ModuleAcademyBorderWrapBodyText, ModuleBorderWrap } from "../../components/VigilanceCard/VigilanceCardElements";
import { useEffect, useRef, useState } from "react";
import TrainingCard from "../../components/TrainingCard";
import potechAcademyWhiteLogo from '../../assets/logos/potechAcademyWhiteLogo.png';
import FooterDivider from "../../components/FooterDivider";
import { useDispatch, useSelector } from "react-redux";
import { resetTargetSection, setTargetSection } from "../../redux/scrollSlice";
import AdvisoryCardMobile from "../../components/AdvisoryCardMobile";
import { DarkivoreFooterImage, DarkivoreFooterNewLine, DarkivoreHistoryFooter, FooterDarkivoreBackground } from "../darkivore/DarkivoreElements";
import { useMediaQuery } from "react-responsive";

import academy1 from '../../assets/images/trainingImage.jpeg';
import academy2 from '../../assets/images/certImage1.png';
import AcademyCardMobile from "../../components/AcademyCardMobile";
import { ACADEMY_CARD_TYPES } from "../../common/constant";
import { Loader, WrapperDiv } from "../../components/loader";
import { Helmet } from "react-helmet";

const Academy = ({ isScrolled }) => {

    const trainingRef = useRef(null);
    const PECBRef = useRef(null);

    const [trainingIndex, setTrainingIndex] = useState(null);
    const [trainingText, setTrainingText] = useState(null);

    const [certIndex, setCertIndex] = useState(null);
    const [certText, setCertText] = useState(null);

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const targetCard = useSelector((state) => state.scroll.targetCard);
    const dispatch = useDispatch();

    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])

    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'training':
                sectionRef = trainingRef;
                break;
            case 'pecb':
                sectionRef = PECBRef;
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            const yOffset = -100;
            const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            dispatch(resetTargetSection());
        }
    }, [targetSection, dispatch]);

    const purpleText = <div>
        Purple Team is a comprehensive training that provides the needed knowledge and skills to combat today's cyber threats.
        Simply said, it enhances your defenses by teaching you all about cyber-attacks!
        <AdvisoryDivLineItemsMobile />
        Purple Team includes a wide spectrum of subjects from understanding generic information security, to tackling system,
        network, and application risks.
        <AdvisoryDivLineItemsMobile />
        Our comprehensive training ensures that your team can proactively simulate attacks
        in order to protect your organization's critical assets.
        <AdvisoryDivLineItemsMobile />
        Through secure development and advanced database security, your specialized technical teams will be well-prepared to
        fortify your infrastructure against potential breaches.
        Topics presented - separately or bundled - are:
        <AdvisoryDivLineItemsMobile />
        - Generic Information Security
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />
        - System Attacks
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Network Attacks
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Application Attacks & Source Code Security

        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />
        - Database Security
    </div>

    const digitalText = <div>
        In the face of security incidents, swift and effective response is crucial. Our Digital Forensics & Incident Response training
        empowers your team to investigate and mitigate the impact of cyber-attacks.
        <AdvisoryDivLineItemsMobile />
        Gaining in-depth knowledge in threat intelligence, system, network, application and email forensics, your security teams will
        be equipped to engage first responder procedures, stop the bleeding, collect and analyze digital evidence, trace incidents,
        and neutralize threats.
        <AdvisoryDivLineItemsMobile />
        For high-level professionals, our training also covers advanced malware analysis and reverse engineering, providing
        invaluable skills in identifying and combating malicious actors.
        Topics presented - separately or bundled - are:
        <AdvisoryDivLineItemsMobile />
        - Computer Forensics
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - System Forensics
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Network Forensics
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Email Forensics
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Application and Database Forensics
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Malware Analysis
    </div>

    const socText = <div>
        Establishing a strong security operations foundation is essential for any organization. Our SOC & SIEM++ Management
        training prepares your team to master the Art of SOC Management!
        <AdvisoryDivLineItemsMobile />
        It includes the management of Security Operations Centers (SOCs) and the use of SOC suite of technologies, from Security
        Information and Event Management (SIEM) to Extended Detection and Response tools.
        <AdvisoryDivLineItemsMobile />
        The training explores log optimization, advanced correlation, alert enrichment, NDR/EDR management and SOC coordination
        and processes.
        <AdvisoryDivLineItemsMobile />
        SOC & SIEM Management will ensure that your Blue Team can swiftly detect and respond to threats. By leveraging techniques
        such as UEBA and cyber deception, you'll stay ahead of attackers and safeguard your critical business assets.
        Topics presented - separately or bundled - are:
        <AdvisoryDivLineItemsMobile />
        - Log Capturing
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - SIEM Management
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Security Operations Center Analyst
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - SOC Management
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Behavioral Monitoring
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Honeypots & Cyber Deception
    </div>

    const complianceText = <div>
        Compliance and strategy are integral to maintaining a secure business environment.
        <AdvisoryDivLineItemsMobile />
        Our training covers essential areas such as risk management, data privacy, national cybersecurity regulations and ISO 27000
        suite.
        <AdvisoryDivLineItemsMobile />
        Your team will gain a comprehensive understanding of risk assessment and mitigation, enabling them to effectively safeguard
        your organization's sensitive data. Additionally, our training ensures compliance with data privacy regulations, including the
        General Data Protection Regulation (GDPR).
        <AdvisoryDivLineItemsMobile />
        By adhering to international standards such as ISO 27001, ISO 27019, you'll establish a robust security framework that inspires
        trust.
        Topics presented - separately or bundled - are:
        <AdvisoryDivLineItemsMobile />
        - Risk Management
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Data Privacy & GDPR
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 27001 - Information security management systems
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 22301 - Security and resilience – Business continuity management systems
    </div>

    const machineText = <div>
        In the rapidly evolving cybersecurity landscape, staying at the forefront of technology is crucial.
        <AdvisoryDivLineItemsMobile />
        Our Machine Learning & Blockchain training equips your team with the knowledge and skills to harness cutting-edge
        technologies.
        <AdvisoryDivLineItemsMobile />
        After exploring blockchain applications and the security considerations involved, your team will gain insights into decentralized
        security frameworks.
        <AdvisoryDivLineItemsMobile />
        By tackling Artificial Intelligence, we cover the full spectrum. From classic machine learning algorithms to unsupervised &
        reinforced learning techniques, we enable your team to detect anomalies, predict emerging threats, and consequently
        strengthen your overall cybersecurity posture.
        Topics presented - separately or bundled - are:
        <AdvisoryDivLineItemsMobile />
        - Blockchain
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Machine Learning & Deep Learning Techniques
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - AI and Data Science
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - NLP/LLM
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - NoSQL Database: Case Study with ElasticSearch
    </div>

    const TrainingItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={academy1} />
        </WrapperDiv>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                PURPLE TEAM
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {purpleText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                DIGITAL FORENSICS & INCIDENT RESPONSE
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {digitalText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                SOC & SIEM MANAGEMENT
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {socText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                COMPLIANCE & STRATEGY
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {complianceText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                MACHINE LEARNING & BLOCKCHAIN
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {machineText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const infoText = <div>
        - ISO 27001
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 27002
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 27005
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 27035
    </div>

    const cyberText = <div>
        - ISO 27032
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 27033
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Cloud security
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Ethical hacking
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Computer forensics
    </div>

    const contText = <div>
        - ISO 22301
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - Disaster recovery
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 22316
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 22317
        <AdvisoryDivLineItemsMobile marginTop={'0vh'} />

        - ISO 22320
    </div>

    const govText = <div>
        - ISO 31000
    </div>

    const DefenseItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={academy2} />
        </WrapperDiv>

        <AdvisoryTextItemsMobile>
            PECB is a certification body that provides valuable evaluation, education, certification, and certificate programs for individuals on a wide range of disciplines, against internationally recognized standards.
            Through their presence in more than 150 countries, they help professionals demonstrate their competence in various areas of expertise.
        </AdvisoryTextItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                INFORMATION SECURITY
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {infoText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                CYBERSECURITY
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {cyberText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                CONTINUITY, RESILIENCE, RECOVERY
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {contText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                GOVERNANCE, RISK AND COMPLIANCE
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {govText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    useEffect(() => {

        switch (targetCard) {
            // training cards
            case ACADEMY_CARD_TYPES.AC_PURPLE:
                setTrainingIndex(1);
                setTrainingText(purpleText);
                break;

            case ACADEMY_CARD_TYPES.AC_DIGITAL:
                setTrainingIndex(2);
                setTrainingText(digitalText);
                break;

            case ACADEMY_CARD_TYPES.AC_SOC:
                setTrainingIndex(3);
                setTrainingText(socText);
                break;

            case ACADEMY_CARD_TYPES.AC_COMP:
                setTrainingIndex(4);
                setTrainingText(complianceText);
                break;

            case ACADEMY_CARD_TYPES.AC_MACHINE:
                setTrainingIndex(5);
                setTrainingText(machineText);
                break;

            // certification cards
            case ACADEMY_CARD_TYPES.CERT_INFO:
                setCertIndex(1);
                setCertText(infoText);
                break;

            case ACADEMY_CARD_TYPES.CERT_CYBER:
                setCertIndex(2);
                setCertText(cyberText);
                break;

            case ACADEMY_CARD_TYPES.CERT_CONT:
                setCertIndex(3);
                setCertText(contText);
                break;

            case ACADEMY_CARD_TYPES.CERT_GOV:
                setCertIndex(4);
                setCertText(contText);
                break;

            default:
                break;
        }

        if (targetCard) {
            dispatch(resetTargetSection());
        }
    }, [targetCard, dispatch]);

    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

    return <>
        <Helmet>
            <title>POTECH Academy: Empower teams to become cybersecurity experts</title>
            <meta name="description" content="Arm your team with the skills to mitigate risks, respond to incidents, and navigate compliance requirements." />
        </Helmet>
        <AcademyBackground>
            <AcademyImg />
            <AcademyTitle>
                <AcademyTextTitle>POTECH ACADEMY</AcademyTextTitle>
                <AcademyTextBody>
                    By investing in our comprehensive trainings and certifications, you empower your technical teams to become cybersecurity experts and brace your business against evolving cyber threats, ensuring long-term success.
                </AcademyTextBody>
                <AcademyTextBody>
                    You also raise non-technical users’ awareness about information security, reducing security-related incidents on your systems and data.
                </AcademyTextBody>
                <AcademyTextBody>
                    Armed with the needed skills to mitigate risks, respond to incidents, and navigate compliance requirements, your organization gains a competitive advantage and your clients rest assured that their data is in capable hands.
                </AcademyTextBody>
                <AcademyButton onClick={() => { trainingRef.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</AcademyButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </AcademyTitle>
        </AcademyBackground>
        <AcademyCover>
            <CornImg>
                <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                            <stop offset="0.253125" stopColor="#d1d1d1" />
                            <stop offset="0.711458" stopColor="#cdcdcd" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornImg>
            <CoverIconContainer width={'22vw'}>
                <CoverDiv onClick={() => { dispatch(setTargetSection('training')) }}>
                    <CoverIcon src={A1} />
                    <div>Training</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('pecb')) }}>
                    <CoverIcon src={A2} />
                    <div>PECB Training <br /> Certifications</div>
                </CoverDiv>
            </CoverIconContainer>
        </AcademyCover>
        {/* training */}
        <TrainingBackground ref={isMobile ? undefined : trainingRef}>
            <TrainingImg />
            <TrainingTitle cardIndex={trainingText}>
                <AcademyEyeIcon src={WTraining} />
                <AcademyBody cardIndex={trainingText}>
                    <AcademyBodyText>
                        Training
                    </AcademyBodyText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={academy1} />
                    </WrapperDiv>
                    <AcademyCardContainer>
                        <TrainingCard text={"PURPLE TEAM"} clickHandler={() => {
                            if (trainingIndex === 1) {
                                setTrainingIndex(null);
                                setTrainingText(null);
                            }
                            else {
                                setTrainingIndex(1);
                                setTrainingText(purpleText)
                            }
                        }} active={trainingIndex === 1} />
                        <TrainingCard text={"DIGITAL FORENSICS & INCIDENT RESPONSE"} clickHandler={() => {
                            if (trainingIndex === 2) {
                                setTrainingIndex(null);
                                setTrainingText(null);
                            }
                            else {
                                setTrainingIndex(2);
                                setTrainingText(digitalText)
                            }
                        }} active={trainingIndex === 2} />
                        <TrainingCard text={"SOC & SIEM MANAGEMENT"} clickHandler={() => {
                            if (trainingIndex === 3) {
                                setTrainingIndex(null);
                                setTrainingText(null);
                            }
                            else {
                                setTrainingIndex(3);
                                setTrainingText(socText)
                            }
                        }} active={trainingIndex === 3} />
                        <TrainingCard text={"COMPLIANCE & STRATEGY"} clickHandler={() => {
                            if (trainingIndex === 4) {
                                setTrainingIndex(null);
                                setTrainingText(null);
                            }
                            else {
                                setTrainingIndex(4);
                                setTrainingText(complianceText)
                            }
                        }} active={trainingIndex === 4} />
                        <TrainingCard text={"MACHINE LEARNING & BLOCKCHAIN"} clickHandler={() => {
                            if (trainingIndex === 5) {
                                setTrainingIndex(null);
                                setTrainingText(null);
                            }
                            else {
                                setTrainingIndex(5);
                                setTrainingText(machineText)
                            }
                        }} active={trainingIndex === 5} />
                    </AcademyCardContainer>
                    {trainingText &&
                        <ModuleAcademyBorderWrap active={true}>
                            <ModuleAcademyBorderWrapBody>
                                <ModuleAcademyBorderWrapBodyText>
                                    {trainingText}
                                </ModuleAcademyBorderWrapBodyText>
                            </ModuleAcademyBorderWrapBody>
                        </ModuleAcademyBorderWrap>
                    }
                </AcademyBody>
            </TrainingTitle>
        </TrainingBackground>
        {/* certification */}
        <TrainingBackground ref={isMobile ? undefined : PECBRef}>
            <TrainingImg />
            <CertTitle cardIndex={certText} >
                <AcademyEyeIcon src={WCert} />
                <CertBody cardIndex={certText}>
                    <AcademyBodyText>
                        PECB Training Certifications
                    </AcademyBodyText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={academy2} />
                    </WrapperDiv>
                    <CertText>
                        PECB is a certification body that provides valuable evaluation, education, certification, and certificate programs for individuals on a wide range of disciplines, against internationally recognized standards.
                    </CertText>
                    <CertText>
                        Through their presence in more than 150 countries, they help professionals demonstrate their competence in various areas of expertise.
                    </CertText>
                    <AcademyCardContainer>
                        <TrainingCard isCertSection={true} text={"INFORMATION SECURITY"} clickHandler={() => {
                            if (certIndex === 1) {
                                setCertIndex(null);
                                setCertText(null);
                            }
                            else {
                                setCertIndex(1);
                                setCertText(infoText)
                            }
                        }} active={certIndex === 1} />
                        <TrainingCard isCertSection={true} text={"CYBERSECURITY"} clickHandler={() => {
                            if (certIndex === 2) {
                                setCertIndex(null);
                                setCertText(null);
                            }
                            else {
                                setCertIndex(2);
                                setCertText(cyberText)
                            }
                        }} active={certIndex === 2} />
                        <TrainingCard isCertSection={true} text={"CONTINUITY, RESILIENCE, RECOVERY"} clickHandler={() => {
                            if (certIndex === 3) {
                                setCertIndex(null);
                                setCertText(null);
                            }
                            else {
                                setCertIndex(3);
                                setCertText(contText)
                            }
                        }} active={certIndex === 3} />
                        <TrainingCard isCertSection={true} text={"GOVERNANCE, RISK AND COMPLIANCE"} clickHandler={() => {
                            if (certIndex === 4) {
                                setCertIndex(null);
                                setCertText(null);
                            }
                            else {
                                setCertIndex(4);
                                setCertText(govText)
                            }
                        }} active={certIndex === 4} />
                    </AcademyCardContainer>
                    {certText &&
                        <ModuleAcademyBorderWrap active={true}>
                            <ModuleAcademyBorderWrapBody>
                                <ModuleAcademyBorderWrapBodyText>
                                    {certText}
                                </ModuleAcademyBorderWrapBodyText>
                            </ModuleAcademyBorderWrapBody>
                        </ModuleAcademyBorderWrap>
                    }
                </CertBody>
            </CertTitle>
        </TrainingBackground>
        <AdvisoryBodyMobile>
            <TrainingBodyMobileImg />
            <AdvisoryDivLineItemsMobile marginTop={'20vh'} />
            {/* isOpen={isOpen === 1} setOpen={() => setOpen(1)} setClose={() => setOpen(null)}
            isOpen={isOpen === 2} setOpen={() => setOpen(2)} setClose={() => setOpen(null)}
            isOpen={isOpen === 3} setOpen={() => setOpen(3)} setClose={() => setOpen(null)}
            isOpen={isOpen === 4} setOpen={() => setOpen(4)} setClose={() => setOpen(null)} */}
            <AcademyCardMobile refA={isMobile ? trainingRef : undefined} logo={WTraining} title={"Training"} items={TrainingItems} />
            <AcademyCardMobile refA={isMobile ? PECBRef : undefined} logo={WCert} title={"PECB TRAINING CERTIFICATIONS"} items={DefenseItems} />
        </AdvisoryBodyMobile>
        <FooterAcademyBackground>
            <FooterAcademyTitle>
                <DarkivoreHistoryFooter>
                    <DarkivoreFooterImage src={potechAcademyWhiteLogo} />
                    <DarkivoreFooterNewLine>
                        We are known to take pleasure in sharing the Potech expertise, widely disseminating our technical knowledge in the information & technology field. In that sense, Potech Academy, active since 2008 is the privileged host of various training workshops, conferences and other such training events, addressed to a wide audience of professionals as well as students in the field.                    </DarkivoreFooterNewLine>
                </DarkivoreHistoryFooter>
            </FooterAcademyTitle>
        </FooterAcademyBackground>
        <FooterDivider />
    </>
}

export default Academy;