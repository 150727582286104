import styled from "styled-components";
import eng1 from '../../assets/images/eng1.png';
import eng2 from '../../assets/images/eng2.png';
import eng3 from '../../assets/images/eng3.png';
import eng4 from '../../assets/images/eng4.png';

import engMobile from '../../assets/images/EngineeringBackgroundHighres.jpeg';

// import imgAdMob from '../../assets/images/advisoryBodyMobile.png';

export const EngBackground = styled.nav`
  width: 100%;
  height: 85vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(90deg, #FFDC00 35.21%, #FBBA00 72.71%);
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const EngImg1 = styled.div`
  width: 100%;
  height: 101%;
  margin-top: -1vh;
  position: absolute;
  background-image: url(${eng1});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const EngImg2 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${eng2});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const EngImg3 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${eng3});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const EngImg4 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${eng4});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-color: yellow;
`;

export const EngineeringTitle = styled.div`
  color: black;
  height: 50vh;
  margin-top: 0vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const EngineeringTextTitle = styled.h1`
  margin-top: 14vh;
  font-size: 1.8vw;
  font-weight: 400;
  margin-bottom: 3vh;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    text-align: center;
    padding-inline: 15%;
    margin-top: 25vh;
  }
`;

export const EngineeringTextBody = styled.div`
    width: 75vw;
    font-weight: 100;
    font-size: 1.2vw;
    text-align: center;
    margin-bottom: 4vh;
    font-family: "HelveticaLt", Arial, sans-serif;
    color: black;
    line-height: 150%;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding-inline: 1%;
      box-sizing: border-box;
      margin-top: 2vh;
    }
`;

// export const EngineeringButton = styled.div`
// border-radius: 6px;
// background: var(--consulting-complementary-gradient, linear-gradient(90deg, #F1D278 25.31%, #D1AA33 71.15%));
// background-blend-mode: multiply;
// /* Drop Shadow Style */
// box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
// color: var(--black, #000);
// text-align: center;
// font-weight: 400;
// width: 7vw;
// padding: 0.5%;
// font-size: 1vw;
// font-family: 'Helvetica';
// @media screen and (max-width: 800px) {
//   width: auto;
//     font-size: 14px;
//     padding: 2%;
//     padding-inline: 5%;
//     margin-top: 6vh;
//   }
// `
export const EngineeringButton = styled.div`
  border-radius: 6px;
  background: var(--white, #FFF);
  background-blend-mode: multiply;
  /* Drop Shadow Style */
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  color: var(--black, #000);
  text-align: center;
  font-weight: 400;
  width: 7vw;
  padding: 0.5%;
  font-size: 1vw;
  margin-top: 1vh;
  font-family: 'Helvetica';
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
    margin-bottom: 12vh;
  }
`;

export const EngineeringCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 35vh;
  /* font-family: 'Helvetica Light'; */
  background: var(--ocean-gradient, linear-gradient(90deg, #e2e2e2 0%, #bdbdbd 100%));
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: multiply;
  position: relative;
  @media screen and (max-width: 800px) {
    display: none;
  }
`
export const EngineeringBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: red; */
  /* overflow: hidden; */
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const AIBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: red; */
  /* overflow: hidden; */
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const EngineeringEyeIcon = styled.img`
  /* position: absolute;
  width: 180px;
  top: 8vh;
  left: 50%;
  transform: translateX(-50%); */

  position: absolute;
  width: 200px;
  top: 11vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 160px;
    top: 11vh;
  }
`;

export const ConstEyeIcon = styled.img`
  /* position: absolute;
  width: 180px;
  top: 8vh;
  left: 50%;
  transform: translateX(-50%); */

  position: absolute;
  width: 200px;
  top: 9vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 160px;
    top: 9vh;
  }
`;

export const ConstTitle = styled.div`
  color: white;
  height: ${props => props.cardIndex ? '220vh' : '170vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
`;

export const ConstBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.cardIndex ? '200vh' : '150vh'};
  /* height: auto; */
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 20vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10vh;
`;

export const EngineeringBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.cardIndex ? '160vh' : '115vh'};
  /* height: auto; */
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 22vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10vh;
`;

export const EngineeringBodyHead = styled.h2`
  color: black;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 1.8vw;
  margin-top: 4vh;
  margin-bottom: -2vh;
  z-index: 1;
`;

export const EngineeringCardContainer = styled.div`
  display: flex;
  width: 59vw;
  justify-content: space-between;
  height: 20vh;
`;

export const ModuleEngineeringBorderWrapBody = styled.div`
  background-color: white;
  width: 59vw;
  border-radius: 12px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  color: black;
  box-sizing: border-box;
  padding: 2%;
  padding-inline: 3%;
`;

export const ModuleEngineeringBorderWrapBodyText = styled.div`
  color: var(--black, #000);
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
`;

export const FooterEngineeringBackground = styled.nav`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  height: auto;
  background: linear-gradient(90deg, #FFDC00 35.21%, #FBBA00 72.71%);
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const PotechQuoteFooterEngineering = styled.div`
  text-align: right;
  font-size: 2.2vw;
  display: flex;
  width: 40vw;
  font-weight: 100;
  color: black;
  margin: auto;
  flex-direction: column;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 75%;
    text-align: start;
    margin-top: 5vh;
  }
`;

export const EngineeringImage = styled.img`
  width: ${props => props.width ?? '5vw'};

  height: 375px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  z-index: 1;
`;

export const PotechHistoryFooterEngineering = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width: 40vw;
  font-size: 1vw;
  color: black;
  @media screen and (max-width: 800px) {
    margin-top: 5vh;
    width: 75%;
  }
`;

export const FooterEngineeringTitle = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 86vw;
  margin: auto;
  
  margin-top: 10vh;
  margin-bottom: 10vh;
  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: 0vh;
    width: 100%;
  }
`;

export const ConsultingBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: red; */
  /* overflow: hidden; */
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const SolutionsBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: red; */
  /* overflow: hidden; */
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const EngineeringFooterAdvisory = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width:  40vw;
  font-size: 1vw;
  @media screen and (max-width: 800px) {
    margin-top: 5vh;
    width: 60%;
  }
`;
export const EngineeringBodyMobile = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  padding-bottom: 10vh;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const EngineeringBodyMobileImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${engMobile});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-position: center;
`;

// export const ImageEngineeringItemsMobile = styled.img`
//   width: 80%;
//   object-fit: cover;
//   box-sizing: border-box;
//   margin-top: 5vh;
//   margin-bottom: 5vh;
//   height: 166px;
//   border-radius: 20px;
// `;
//
// export const EngineeringBackground = styled.nav`
//   width: 100%;
//   height: 85vh;
//   position: relative;
//   overflow-x: hidden;
//   overflow-y: hidden;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;
export const EngineeringDivHeaderItemsMobile = styled.div`

  color: black;
  /* Resp-Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px #CECECE solid;
  text-align: start;
  padding: 6%;
  padding-inline: 9%;
`;
export const ImageEngineeringItemsMobile = styled.img`
  width: 80%;
  object-fit: cover;
  box-sizing: border-box;
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 166px;
  border-radius: 20px;
  z-index: 1;
`;
