import axios from "axios";
import { apiURL } from "../config/global-params";

axios.defaults.baseURL = apiURL;

const responseBody = (response) => response.data;

const requests = {
    get: (url) => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    patch: (url, body, headers) => axios.patch(url, body,
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
                'Accept': 'application/ld+json'
            }
        }
    ).then(responseBody),

    put: (url, body) => axios.put(url, body).then(responseBody),
    delete: (url) => axios.delete(url).then(responseBody),
}

const News = {
    'apiName': 'newsletters',
    postEmail: (body) => requests.post(News.apiName, body)
}
const Contact = {
    'apiName': 'contact_messages',
    postMessage: (body) => requests.post(Contact.apiName, body)
}

const agent = {
    News,
    Contact
}

export default agent;